import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  constructor() {}

  saveStorage(name: string, value: any, encode: boolean = false) {
    const valueToString: string = JSON.stringify(value);
    localStorage.setItem(name, encode ? btoa(valueToString) : valueToString);
  }

  returnStorage(name: string, isEncoded: boolean = false) {
    try {
      if (localStorage.getItem(name)) {
        const storage: any = localStorage.getItem(name);
        const value: any = isEncoded ? atob(storage) : storage;
        return JSON.parse(value);
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  deleteStorage(name: string) {
    if (localStorage.getItem(name)) {
      localStorage.removeItem(name);
    }
  }
}
