import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.url.includes(environment.api.split('//')[1])) {
      const header = request.clone({
        headers: request.headers.set(
          'authorization-header',
          environment.header
        ),
      });
      return next.handle(header);
    } else {
      return next.handle(request);
    }
  }
}
