import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LocalStorageService } from '../services/local-storage.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private storage: LocalStorageService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (!request.url.includes(`${environment.api.split('//')[1]}/auth`)) {
      const header = request.clone({
        headers: request.headers.set(
          'authorization-token',
          this.storage.returnStorage('token')
        ),
      });
      return next.handle(header);
    } else {
      return next.handle(request);
    }
  }
}
