import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private api: string = environment.api;
  private usuario: any;

  constructor(private http: HttpClient, private storage: LocalStorageService) {}

  postLogin(credenciales: any) {
    return this.http.post(`${this.api}/auth`, credenciales).pipe(
      tap((res: any) => {
        this.guardarUsuario(res);
      })
    );
  }

  logOut() {
    this.storage.deleteStorage('token');
  }

  guardarUsuario(res: any) {
    this.storage.saveStorage('usuario', res.usuario, true);
    this.storage.saveStorage('token', res.token);
  }

  cargarUsuario() {
    this.usuario = this.storage.returnStorage('usuario', true);
    return this.usuario;
  }

  isAuthenticated(): boolean {
    try {
      if (!this.storage.returnStorage('token')) {
        return false;
      } else {
        return true;
      }
    } catch (error) {
      return false;
    }
  }
}
